var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employees-profile"},[_c('div',{staticClass:"employees-profile__content main-content main-color"},[_c('div',{staticClass:"employees-profile__top main-line"},[_c('div',{staticClass:"employees-profile__title"},[(_vm.$route.name == 'AdminEmployeesView')?_c('h4',[_vm._v("Просмотр профиля")]):_c('h4',[_vm._v("Регистрация нового сотрудника")])]),(_vm.$route.name == 'AdminEmployeesView')?_c('div',{staticClass:"main-filter"},[_c('div',{staticClass:"main-filter__block"},[_c('button',{staticClass:"main-filter__button",class:{
              'main-filter__button_active':
                _vm.$route.name == 'AdminEmployeesView',
            }},[_vm._v(" Профиль ")])]),_vm._m(0)]):_vm._e()]),_c('div',{staticClass:"employees-profile__body"},[_c('div',{staticClass:"employees-profile__form"},[(_vm.currentUser)?_c('div',{staticClass:"employees-profile__block"},[_c('div',{staticClass:"employees-profile__row"},[_c('div',{staticClass:"employees-profile__image"},[_c('img',{staticClass:"employees-profile__image",attrs:{"type":"image","src":require('@/assets/images/users/avatar2.png'),"alt":"user"}})]),_c('div',{staticClass:"employees-profile__info"},[_c('p',{staticClass:"employees-profile__name"},[_vm._v(" "+_vm._s(_vm.currentUser.name)+" "+_vm._s(_vm.currentUser.surname)+" "+_vm._s(_vm.currentUser.email)+" ")]),_c('ul',{staticClass:"main-table__list main-table__list"},_vm._l((_vm.currentUser.availableCountries),function(country){return _c('li',{key:country},[_c('div',{staticClass:"main-table__icon"},[_c('img',{attrs:{"src":require(`@/assets/images/icons/flags/${country.toLowerCase()}.svg`),"alt":""}})]),_c('p',{staticClass:"main-table__text main-table__text_bold"},[_vm._v(" "+_vm._s(country)+" ")])])}),0)])])]):_vm._e(),_c('div',{staticClass:"employees-profile__block"},[_c('div',{staticClass:"employees-profile__row employees-profile__row_direction"},[_c('div',{staticClass:"employees-profile__field",class:{
                'employees-profile__field_error': _vm.errorsToSubmit.name,
              }},[_c('label',{attrs:{"for":"EmployeesProfileName"}},[_c('div',{staticClass:"employees-profile__icon"},[_c('StarIcon')],1),_c('p',[_vm._v("Имя(имя и отчество):")])]),_c('div',{staticClass:"employees-profile__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changedUser.name),expression:"changedUser.name"}],attrs:{"type":"text","id":"EmployeesProfileName","placeholder":"Введите имя"},domProps:{"value":(_vm.changedUser.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.changedUser, "name", $event.target.value)}}}),_c('div',{staticClass:"employees-profile__error"},[_c('ErrorIcon')],1)])]),_c('div',{staticClass:"employees-profile__field",class:{
                'employees-profile__field_error': _vm.errorsToSubmit.surname,
              }},[_c('label',{attrs:{"for":"EmployeesProfileSurname"}},[_c('div',{staticClass:"employees-profile__icon"},[_c('StarIcon')],1),_c('p',[_vm._v("Фамилия:")])]),_c('div',{staticClass:"employees-profile__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changedUser.surname),expression:"changedUser.surname"}],attrs:{"type":"text","id":"EmployeesProfileSurname","placeholder":"Введите фамилию"},domProps:{"value":(_vm.changedUser.surname)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.changedUser, "surname", $event.target.value)}}}),_c('div',{staticClass:"employees-profile__error"},[_c('ErrorIcon')],1)])]),_c('div',{staticClass:"employees-profile__field",class:{
                'employees-profile__field_error': _vm.errorsToSubmit.email,
              }},[_c('label',{attrs:{"for":"EmployeesProfileEmail"}},[_c('div',{staticClass:"employees-profile__icon"},[_c('StarIcon')],1),_c('p',[_vm._v("e-mail:")])]),_c('div',{staticClass:"employees-profile__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changedUser.email),expression:"changedUser.email"}],attrs:{"type":"text","id":"EmployeesProfileEmail","placeholder":"Введите E-mail"},domProps:{"value":(_vm.changedUser.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.changedUser, "email", $event.target.value)}}}),_c('div',{staticClass:"employees-profile__error"},[_c('ErrorIcon')],1)])])])]),_c('div',{staticClass:"employees-profile__block"},[_c('div',{staticClass:"employees-profile__subtitle"},[_c('div',{staticClass:"employees-profile__icon"},[_c('StarIcon')],1),_c('h4',[_vm._v("Доступ к публикациям")])]),_c('div',{staticClass:"employees-profile__row"},[_c('ul',{staticClass:"main-table__list main-table__list employees-profile__access"},[_vm._l((_vm.allCountries),function(country){return _c('li',{key:country,staticClass:"employees-profile__country",class:{
                  'employees-profile__country_active':
                    _vm.changedUser.availableCountries
                      ? _vm.changedUser.availableCountries.includes(country)
                      : false,
                },on:{"click":function($event){return _vm.changeCountry(country)}}},[_c('div',{staticClass:"main-table__icon"},[_c('img',{attrs:{"src":require(`@/assets/images/icons/flags/${country.toLowerCase()}.svg`),"alt":""}})]),_c('p',{staticClass:"main-table__text main-table__text_bold"},[_vm._v(" "+_vm._s(country)+" ")])])}),_c('li',[_c('button',{staticClass:"button-red",on:{"click":function($event){return _vm.changeCountry('Все')}}},[_vm._v(" Выбрать все ")])])],2)]),_c('div',{staticClass:"employees-profile__block"},[_c('div',{staticClass:"employees-profile__subtitle"},[_c('div',{staticClass:"employees-profile__icon"},[_c('StarIcon')],1),_c('h4',[_vm._v("Роли")])]),_c('div',{staticClass:"employees-profile__row"},[_c('ul',{staticClass:"employees-profile__roles"},_vm._l((_vm.allRoles),function(role){return _c('li',{key:role,on:{"click":function($event){return _vm.toggleArray(_vm.changedUser.roles, role)}}},[_c('div',{staticClass:"employees-profile__checkbox main-checkbox"},[_c('input',{staticClass:"main-checkbox__input",attrs:{"type":"checkbox"},domProps:{"checked":_vm.changedUser.roles.some((e) => e == role)
                          ? true
                          : false}}),_c('div',{staticClass:"main-checkbox__checkmark"}),_c('div',{staticClass:"checkbox__body"})]),_c('p',{style:({
                      'background-color':
                        role == 'модератор'
                          ? '#C0FBFF'
                          : role == 'редактор'
                          ? '#D0FFD2'
                          : '#FFDDDD',
                    })},[_vm._v(" "+_vm._s(role)+" ")])])}),0)])])])]),_c('div',{staticClass:"employees-profile__form"},[_vm._m(1),_c('div',{staticClass:"employees-profile__block"},[_c('div',{staticClass:"employees-profile__subtitle employees-profile__subtitle_light"},[_c('div',{staticClass:"employees-profile__icon"},[_c('StarIcon')],1),_c('h4',[_vm._v("Обязательно для заполнения")])]),_c('div',{staticClass:"employees-profile__row employees-profile__row_grid"},[_c('div',{staticClass:"employees-profile__image employees-profile__image_big"},[_c('img',{attrs:{"type":"image","src":_vm.imageToShow || require('@/assets/images/users/empty.png'),"alt":"user"}})]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"employees-profile__button"},[_c('label',{staticClass:"button-red",attrs:{"for":"uploadPhoto"}},[_vm._v("Изменить фото")]),_c('input',{staticClass:"employees-profile__upload",attrs:{"type":"file","id":"uploadPhoto","accept":"image/jpeg, image/png, image.jpg"},on:{"change":_vm.uploadImage}})])])])]),_c('div',{staticClass:"employees-profile__form"},[_vm._m(4),_c('div',{staticClass:"employees-profile__block employees-profile__block"},[_c('div',{staticClass:"employees-profile__row employees-profile__row_direction"},[_c('div',{staticClass:"employees-profile__field",class:{
                'employees-profile__field_error': _vm.errorsToSubmit.password,
              }},[_vm._m(5),_c('div',{staticClass:"employees-profile__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.changedUser.password),expression:"changedUser.password"}],attrs:{"type":"password","id":"EmployeesProfilePassword","placeholder":"Введите новый пароль"},domProps:{"value":(_vm.changedUser.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.changedUser, "password", $event.target.value)}}}),_c('div',{staticClass:"employees-profile__error"},[_c('ErrorIcon')],1)])]),_c('div',{staticClass:"employees-profile__field",class:{
                'employees-profile__field_error': _vm.errorsToSubmit.password,
              }},[_vm._m(6),_c('div',{staticClass:"employees-profile__input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.repeatPassword),expression:"repeatPassword"}],attrs:{"type":"password","id":"EmployeesProfileRepeatPassword","placeholder":"Подтвердите пароль"},domProps:{"value":(_vm.repeatPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.repeatPassword=$event.target.value}}}),_c('div',{staticClass:"employees-profile__error"},[_c('ErrorIcon')],1)])])])])])])]),(_vm.currentUser == null)?_c('div',{staticClass:"main-submit"},[_c('button',{on:{"click":function($event){return _vm.saveData('newUser')}}},[_vm._v(" Зарегестрировать пользователя ")])]):_c('div',{staticClass:"main-submit"},[_c('button',{on:{"click":function($event){return _vm.saveData('saveChange')}}},[_vm._v("Сохранить изменения")]),_c('button',{on:{"click":function($event){return _vm.saveData('deleteUser')}}},[_vm._v("Удалить профиль")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-filter__block"},[_c('button',{staticClass:"main-filter__button"},[_vm._v("KPI")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employees-profile__block employees-profile__title main-line"},[_c('h4',[_vm._v("Загрузить фото")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employees-profile__require"},[_c('p',[_vm._v("Требование к фото:")]),_c('p',[_vm._v(" объем не более 1МБ, размер не менее 200х200 пикселей, формат jpg, jpeg, png ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employees-profile__require"},[_c('p',[_vm._v("Просьба")]),_c('p',[_vm._v(" Постарайтесь, пожалуйста, подобрать фото или картинку так, чтобы она хорошо читалась. Была яркая и внятная. Всегда приятно при общении видеть лицо собеседника. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employees-profile__block employees-profile__title main-line"},[_c('h4',[_vm._v("Пароль")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"EmployeesProfilePassword"}},[_c('p',[_vm._v("Введите новый пароль:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"EmployeesProfileRepeatPassword"}},[_c('p',[_vm._v("Подтвердите пароль:")])])
}]

export { render, staticRenderFns }